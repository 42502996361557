import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { endPoint, endPointMutation } from '../../../api/endPoint';
import style from '../../../assets/css/ProductDetail.module.scss';
import { colorList } from '../../../assets/style/theme';
import BottomMenuProductDetail from '../../../components/BottomMenuProductDetail.jsx';
import BtnBack from '../../../components/BtnBack.jsx';
import BtnNormal from '../../../components/BtnNormal';
import ErrorFetching from '../../../components/error/ErrorFetching';
import FloatingMenu from '../../../components/FloatingMenu';
import InputBox from '../../../components/InputBox';
import ImageLayout from '../../../components/layout/ImageLayout.jsx';
import SectionLayout from '../../../components/layout/SectionLayout.jsx';
import { useMediaQueryCustom } from '../../../components/mediaQueryCustom/useMediaQueryCustom.js';
import { usePopup } from '../../../components/popup/usePopup.js';
import SelectCustom from '../../../components/SelectCustom.jsx';
import SEOHelmet from '../../../components/seo/SEOHelmet';
import TextEditor_Quill_ReadOnly from '../../../components/TextEditor_Quill_ReadOnly';
import ellipsisTextString from '../../../components/textLimit/ellipsisTextString';
import { useScrollEvent } from '../../../components/useScrollEvent/useScrollEvent.js';
import { DELIVERY_CODE } from '../../../constant/DELIVERY_CODE.js';
import { ORDER_STATUS, PAYMENT_TYPE, WS_MESSAGE_TYPE } from '../../../constant/TYPE_CONSTANT';
import { queryKeys } from '../../../react-query/queryKeyConstant';
import { useMutationInstance } from '../../../react-query/useMutationInstance';
import { useQueryInstance } from '../../../react-query/useQueryInstance';
import { payFinalData } from '../../../recoil/payFinalData';
import { decodingCrypto } from '../../../utils/crypto';
import { dateFormatHelper } from '../../../utils/dateHelper.js';
import { exportOptionData } from '../../../utils/exportOptionData.js';
import { htmlTagRemove } from '../../../utils/htmlTagRemove';
import { IsLoggedIn } from '../../../utils/IsLoggedIn';
import { changeEnteredNumComma } from '../../../utils/numberComma';
import VATCalculate from '../../../utils/VATCalculate';

const { REACT_APP_CLIENT_TEST_KEY } = process.env;
const { REACT_APP_CLIENT_REAL_KEY } = process.env;

function ProductDetail({ setHeaderUpdated }) {
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { openPopup } = usePopup();

  const isLoggedIn = IsLoggedIn();

  const loginMemberIdx = Number(decodingCrypto(localStorage.getItem('idx')));

  const { idx } = useParams();

  const [productImgList, setProductImgList] = useState([]);

  // input카운터 제어
  const [orderCount, setOrderCount] = useState(1);

  // 결제방식 선택
  const [payType, setPayType] = useState('transfer');

  // 품절 체크
  const [isSoldOut, setIsSoldOut] = useState(null);

  const orderCountHandler = useCallback((e) => {
    const value = e.target.value;
    if (value < 0) return;
    setOrderCount(!value ? value : Number(value));
  }, []);

  // 옵션 state
  const [option, setOption] = useState(null);

  const { data: item, isError } = useQueryInstance({
    queryKey: [queryKeys.product, idx],
    apiMethod: 'get',
    apiEndPoint: endPoint.product.getProduct,
    apiPathParams: idx,
    selectFn: (data) => {
      return {
        ...data,
        data: { ...data.data, properties: JSON.parse(data.data.properties) },
      };
    },
    onSuccess: (data) => {
      const originData = data?.data;

      if (originData) {
        setProductImgList(originData.product_img_list);
        setSelectedImage(originData.product_img_list[0].img_url);

        const optionList = null;
        setIsSoldOut(() =>
          !optionList ? originData.stock_quantity < originData.min_order_quantity : false,
        );

        // 옵션사항 있을 시 옵션 초기 세팅
        optionList > 0 && setOption('0');
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 0,
    cacheTime: 5 * 60 * 1000,
  });

  // 옵션 체크 및 재고 수량 체크 핸들러
  const optionCheckHandler = useCallback(
    (e) => {
      const optionIdx = Number(e.target.value);
      setOption(optionIdx);

      if (optionIdx !== 0) {
        const optionData = exportOptionData(item.data?.product_option_list, optionIdx);

        const soldOutCheck = optionData.stock_quantity < optionData.min_order_quantity;
        setIsSoldOut(soldOutCheck);
      } else {
        setIsSoldOut(false);
      }
    },
    [option],
  );

  const shareUrl = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      openPopup({ title: '안내', content: '주소가 복사되었습니다.' });
    });
  };

  const increaseOrderCount = useCallback(() => {
    setOrderCount((prevCount) => prevCount + 1);
  }, []);

  const decreaseOrderCount = useCallback(() => {
    if (orderCount > 1) {
      setOrderCount((prevCount) => prevCount - 1);
    }
  }, [orderCount]);

  // 총 금액 계산
  const calculateTotalPrice = () => {
    if (!item?.data?.price || isNaN(item?.data?.price) || isNaN(orderCount)) {
      return 0; // 혹은 다른 기본값
    }
    return changeEnteredNumComma(item?.data?.price * orderCount);
  };

  const { mutate: addCartMutate } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.cart.saveCart,
    onSuccessFn: () => {
      openPopup({ title: '안내', content: '장바구니에 추가되었습니다.' });
      setHeaderUpdated((prev) => !prev);
      queryClient.refetchQueries([queryKeys.cartItems], { force: true });
    },
    onErrorFn: () => {
      openPopup({
        title: '오류',
        content: '다시 시도해주세요.',
      });
    },
  });

  const addCart = useCallback(() => {
    if (!isLoggedIn) {
      window.scrollTo(0, 0);
      return navigate('/pages/member/login');
    }

    if (loginMemberIdx == item?.data?.reg_by_member_idx) {
      openPopup({
        title: '안내',
        content: '회원님이 등록한 상품입니다.',
      });
      return;
    }

    // 옵션항목 체크
    const optioList = item?.data?.product_option_list;
    // 옵션있을시 디폴트인지 체크
    if (optioList?.length > 0) {
      if (Number(option) === 0) {
        return openPopup({
          title: '안내',
          content: '옵션을 선택해주세요.',
        });
      }
    }

    // 장바구니 추가용 데이터
    const addCartData = {
      product_idx: parseInt(idx),
      count: orderCount,
      product_option_idx: option ? Number(option) : null,
    };

    addCartMutate({
      apiBody: addCartData,
    });
  }, [orderCount, idx, item?.data?.reg_by_member_idx, option]);

  // 리코일로 결제정보데이터 보관
  const setPayFinalData = useSetRecoilState(payFinalData);

  const { mutate: apiOnPayFinal } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.order.addOrder,
    onSuccessFn: (response) => {
      setPayFinalData((cur) => ({ ...cur, PCD_PAY_OID: response?.data?.order_number }));
      queryClient.refetchQueries([queryKeys.orderListMypage], { force: true });
      // 데이터 전송 되었으므로 세션스토리지 초기화
      sessionStorage.removeItem('delivery');
      navigate('/pages/product/pay/final');
    },
    onErrorFn: (error) => {
      const serverMessage = error.response.data.message;
      // 데이터 오류이므로 세션스토리지 초기화
      sessionStorage.removeItem('delivery');

      openPopup({
        title: '오류',
        content: serverMessage,
      });
    },
  });

  const apiOn = (paymentResult) => {
    const deliveryData = sessionStorage.getItem('delivery');
    const { deliveryName } = deliveryData && JSON.parse(deliveryData);

    const orderItemList = [
      {
        product_idx: parseInt(idx),
        price: VATCalculate(calculateTotalPrice().replaceAll(',', '')),
        count: orderCount,
        product_option_idx: option ? Number(option) : null,
      },
    ];

    const properties = {
      shipping_company_name: deliveryName,
      payment_result: paymentResult,
    };

    // 최종 데이터
    const addOrderData = {
      order_item_list: orderItemList,
      payment_type: payType === 'card' ? PAYMENT_TYPE.CARD : PAYMENT_TYPE.TRANSFER,
      order_status: ORDER_STATUS.ORDER,
      properties: JSON.stringify(properties),
    };

    // 결제 파이널 페이지로 넘어가는 것을 위해 전역상태보관
    const {
      PCD_PAY_RST,
      PCD_PAY_MSG,
      PCD_PAYER_NAME,
      PCD_PAY_GOODS,
      PCD_PAY_TIME,
      PCD_PAY_TYPE,
      PCD_PAY_BANKNAME,
      PCD_PAY_BANKNUM,
      PCD_PAY_CARDNAME,
      PCD_PAY_CARDNUM,
      PCD_PAY_TOTAL,
    } = paymentResult;

    setPayFinalData((cur) => ({
      ...cur,
      PCD_PAY_RST,
      PCD_PAY_MSG,
      PCD_PAYER_NAME,
      PCD_PAY_GOODS,
      PCD_PAY_TIME,
      PCD_PAY_TYPE,
      PCD_PAY_BANKNAME,
      PCD_PAY_BANKNUM,
      PCD_PAY_CARDNAME,
      PCD_PAY_CARDNUM,
      PCD_PAY_TOTAL,
      Delivery: deliveryName,
      OptionName:
        option && Number(option) !== 0
          ? exportOptionData(item.data?.product_option_list, Number(option))?.name
          : '',
    }));

    apiOnPayFinal({
      apiBody: addOrderData,
    });
  };

  const selectDelivery = (name) => {
    if (name) {
      const deliveryData = {
        deliveryName: name,
      };

      // 세션스토리지에 택배사 데이터 저장
      sessionStorage.setItem('delivery', JSON.stringify(deliveryData));

      openPopup({
        title: '안내',
        content: '택배사 : ' + name + '\n\n정말 결제하시겠습니까?',
        onComplete: () => onPayple(),
        twoButton: true,
      });
    } else {
      openPopup({
        title: '안내',
        content: '택배사를 입력해주세요.',
      });
    }
  };

  const { mutate: onCheckBeforeOrder } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.order.checkBeforeOrder,
    onSuccessFn: (response) => {
      openPopup({
        title: '안내',
        content: '택배사를 입력해주세요. \n * 택배비는 착불입니다.',
        twoButton: true,
        input: false,
        select: DELIVERY_CODE,
        selectEtcInput: true,
        setSelectValue: selectDelivery,
      });
    },
    onErrorFn: (error) => {
      const serverMessage = error.response.data.message;

      openPopup({
        title: '오류',
        content: serverMessage,
      });
    },
  });

  const onCheckBeforeOrderFunc = () => {
    const orderItemList = [
      {
        product_idx: parseInt(idx),
        price: calculateTotalPrice().replaceAll(',', ''),
        count: orderCount,
        product_option_idx: option ? Number(option) : null,
      },
    ];

    const data = {
      order_item_list: orderItemList,
    };

    // 재고수량 및 구매 가능 여부 선체크 여기서 true 여야 결제 진행해야함
    onCheckBeforeOrder({
      apiBody: data,
    });
  };

  const addOrder = () => {
    if (!isLoggedIn) {
      window.scrollTo(0, 0);
      return navigate('/pages/member/login');
    }
    // 세션스토리지 초기화
    sessionStorage.removeItem('delivery');

    if (loginMemberIdx == item?.data?.reg_by_member_idx) {
      openPopup({
        title: '안내',
        content: '회원님이 등록한 상품입니다.',
      });
      return;
    }

    // 옵션항목 체크
    const optioList = item?.data?.product_option_list;

    // 옵션 없을시 최소주문수량 체크
    if (optioList?.length === 0 && item?.data?.min_order_quantity > orderCount) {
      openPopup({
        title: '안내',
        content: '최소 주문수량은 ' + item?.data?.min_order_quantity + '개 입니다.',
      });
      return;
    }

    // 옵션있을시 최소주문수량 체크
    if (optioList?.length > 0) {
      if (Number(option) === 0) {
        return openPopup({
          title: '안내',
          content: '옵션을 선택해주세요.',
        });
      }

      // 옵션 분류
      const optionData = exportOptionData(item.data?.product_option_list, Number(option));

      if (optionData.min_order_quantity > orderCount)
        return openPopup({
          title: '안내',
          content: '최소 주문수량은 ' + optionData.min_order_quantity + '개 입니다.',
        });
    }

    onCheckBeforeOrderFunc();
  };

  const onPayple = () => {
    let obj = new Object();

    obj.PCD_PAY_TYPE = payType;
    // 계좌결제
    // obj.PCD_PAY_TYPE = "transfer";
    obj.PCD_PAY_WORK = 'PAY';

    /* 01 : 페이플 간편결제 */
    // obj.PCD_CARD_VER = "01"
    /* 02 : 페이플 앱카드결제 */
    obj.PCD_CARD_VER = payType === 'card' ? '02' : '01';

    obj.PCD_PAYER_NAME = decodingCrypto(localStorage.getItem('member_name'));
    obj.PCD_PAYER_HP = decodingCrypto(localStorage.getItem('member_phone_number'));
    // obj.PCD_PAYER_EMAIL = '';
    obj.PCD_PAY_GOODS =
      item?.data?.name +
      (option ? `(${exportOptionData(item.data?.product_option_list, Number(option))?.name})` : '');
    obj.PCD_PAY_TOTAL = VATCalculate(calculateTotalPrice().replaceAll(',', ''));
    obj.PCD_PAY_ISTAX = 'Y';

    /* 결과를 콜백 함수로 받고자 하는 경우 함수 설정 추가 */
    obj.callbackFunction = getPaypleResult; // 콜백 함수

    obj.clientKey =
      window.location.hostname === 'www.ilppang.com'
        ? REACT_APP_CLIENT_REAL_KEY
        : REACT_APP_CLIENT_TEST_KEY;

    obj.PCD_RST_URL = '/pages/product/detail/' + idx; // 결제결과 수신 URL

    PaypleCpayAuthCheck(obj);
  };

  const getPaypleResult = (result) => {
    if (result) {
      if (result.PCD_PAY_RST === 'close') {
        openPopup({
          title: '안내',
          content: '결제를 취소하였습니다.',
        });
      } else if (result.PCD_PAY_RST === 'success') {
        // api 호출
        apiOn(result);
      } else {
        openPopup({
          title: '안내',
          content: result.PCD_PAY_MSG,
        });
      }
    }
  };

  const [selectedImage, setSelectedImage] = useState('');

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  // 반응형 포인트
  const {
    FULL_SCREEN,
    LARGE_SCREEN,
    MEDIUM_SMALL_SCREEN,
    TABLET_SCREEN,
    SMALL_SCREEN,
    EXTRA_SMALL_SCREEN,
  } = useMediaQueryCustom();

  // 스크롤이벤트 포인트
  const { 600: toastMenuPoint, 900: toastMenuPointSmallScreen } = useScrollEvent({
    points: [600, 900],
    eventActive: TABLET_SCREEN,
  });

  // 견적chat 핸들
  const { mutate: reqEstimateAPI } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.chat.createRoom,
    onErrorFn: () => {
      openPopup({ title: '오류', content: '다시 시도해주세요.' });
    },
    onSuccessFn: (response) => {
      const chatRoomIdx = response.data.chat_room_idx;
      navigate(`/pages/member/mypage/my_estimate_index/detail/${chatRoomIdx}`);
    },
  });

  // 채팅 요청 API
  const reqEstimateAPIHandler = () => {
    const extraData = {
      name: item?.data?.name,
      price: item?.data?.price,
      product_idx: item?.data?.idx,
      img_url: item?.data?.product_img_list[0].img_url,
      min_order_quantity: item?.data?.min_order_quantity,
      stock_quantity: item?.data?.stock_quantity,
      is_initial: true,
    };

    reqEstimateAPI({
      apiBody: {
        sender_idx: loginMemberIdx,
        product_idx: item?.data?.idx,
        receiver_idx: item?.data?.reg_by_member_idx,
        message_type: WS_MESSAGE_TYPE.INQUIRY,
        extra_data: JSON.stringify(extraData),
      },
    });
  };

  const reqEstimate = useCallback(async () => {
    if (loginMemberIdx == item?.data?.reg_by_member_idx) {
      openPopup({
        title: '안내',
        content: '회원님이 등록한 상품입니다.',
      });
    } else {
      openPopup({
        title: '안내',
        content: '견적문의를 진행하시겠습니까?',
        twoButton: true,
        onComplete: reqEstimateAPIHandler,
      });
    }
  }, [item?.data, loginMemberIdx]);

  if (isError) return <ErrorFetching isError={isError} />;

  return (
    <>
      <SEOHelmet
        title={`일빵 | 제품: ${
          ellipsisTextString({ text: item?.data?.name, maxLength: 25 }) || ''
        }`}
        contentTitle="제품상세정보"
        description={ellipsisTextString({
          text:
            htmlTagRemove(item?.data?.content) !== ''
              ? htmlTagRemove(item?.data?.content)
              : undefined,
          maxLength: 90,
        })}
        keywords="일빵, MRO, 제품, 소모성자재, 도매판매, 상품상세보기"
      />

      <SectionLayout padding={MEDIUM_SMALL_SCREEN ? '40px 20px' : undefined}>
        <div className={style.top_btn_box}>
          <BtnBack
            fontSize={MEDIUM_SMALL_SCREEN ? '12px' : undefined}
            padding={MEDIUM_SMALL_SCREEN ? '7px' : undefined}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            {!isSoldOut && isLoggedIn && (
              <BtnNormal bgColor={colorList.darkBlue} padding="10px" onClickEvent={reqEstimate}>
                견적문의
              </BtnNormal>
            )}
            <button type="button" className={style.share_icon} onClick={shareUrl} />
          </div>
        </div>

        <div className={style.detail_head}>
          <div className={style.thumbnail_box}>
            {!SMALL_SCREEN && (
              <ImageLayout
                imgSrc={selectedImage}
                isSoldOutFontSize="clamp(40px, 10vw, 50px)"
                isSoldOut={isSoldOut === true}
                alt="상품상세이미지"
              />
            )}
            {SMALL_SCREEN && (
              <div className={style.product_image_wrap}>
                <ImageLayout
                  imgSrc={selectedImage}
                  isSoldOutFontSize="clamp(40px, 10vw, 50px)"
                  isSoldOut={isSoldOut === true}
                  alt="상품상세이미지"
                />
              </div>
            )}

            <div className={style.thumbnail_list}>
              {productImgList?.map((productImg, index) => {
                return (
                  <div
                    className={style.thumbnail_item}
                    key={index}
                    style={{ backgroundImage: `url('${productImg.img_url}')` }}
                    onClick={() => handleThumbnailClick(productImg.img_url)}
                  />
                );
              })}
            </div>
          </div>

          <div className={style.detail_box}>
            <p className={style.title_name}>{item?.data?.name}</p>
            <div className={style.bot}>
              <div className={style.info}>
                <div className={style.info_item}>
                  <p className={style.title}>회사명</p>
                  <p className={style.con}>{item?.data?.company_name}</p>
                </div>
                <div className={style.info_item}>
                  <p className={style.title}>규격</p>
                  <p className={style.con}>{item?.data?.standard}</p>
                </div>
                <div className={style.info_item}>
                  <p className={style.title}>단위</p>
                  <p className={style.con}>{item?.data?.product_unit}</p>
                </div>
                <div className={style.info_item}>
                  <p className={style.title}>용도</p>
                  <p className={style.con}>{item?.data?.product_usage}</p>
                </div>
                <div className={style.info_item}>
                  <p className={style.title}>원산지</p>
                  <p className={style.con}>{item?.data?.properties?.made_by_country}</p>
                </div>
                <div className={style.info_item}>
                  <p className={style.title}>제조업체</p>
                  <p className={style.con}>{item?.data?.properties?.made_by_company}</p>
                </div>

                <div className={style.info_item}>
                  <p className={style.title}>등록일</p>
                  <p className={style.con}>{dateFormatHelper(item?.data?.reg_by_time)}</p>
                </div>
              </div>

              {isSoldOut === false && isLoggedIn && (
                <div className={style.buy_box}>
                  {item.data?.product_option_list?.length > 0 && (
                    <div className={style.option_box}>
                      {/*<p className={style.title}>*/}
                      {/*  <span style={{ color: 'red' }}>*</span>옵션*/}
                      {/*</p>*/}
                      <SelectCustom
                        fontSize="15px"
                        padding="0 0 0 12px"
                        flex="7"
                        maxWidth="auto"
                        data={[
                          { label: '상품을 선택해 주세요.', value: 0 },
                          ...(item.data?.product_option_list?.map((el) => ({
                            label: ellipsisTextString({ text: el.name, maxLength: 12 }),
                            value: el.product_option_idx,
                          })) || []),
                        ]}
                        value={option ?? '0'}
                        onChangeEvent={optionCheckHandler}
                      />
                    </div>
                  )}
                  <div className={style.con}>
                    {MEDIUM_SMALL_SCREEN && (
                      <p className={style.total}>
                        <span className={style.price}>
                          {isLoggedIn ? changeEnteredNumComma(item?.data?.price) : null}
                        </span>
                        <span className={style.won}>{isLoggedIn ? '원' : null}</span>
                      </p>
                    )}
                    {isLoggedIn && (
                      <div className={style.num}>
                        <button
                          type="button"
                          className={style.num_minus}
                          onClick={decreaseOrderCount}
                        />
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          className={style.num_input}
                          value={orderCount}
                          onChange={orderCountHandler}
                        />
                        <button
                          type="button"
                          className={style.num_plus}
                          onClick={increaseOrderCount}
                        />
                      </div>
                    )}

                    {!MEDIUM_SMALL_SCREEN && (
                      <p className={style.total}>
                        <span className={style.price}>
                          {isLoggedIn ? changeEnteredNumComma(item?.data?.price) : null}
                        </span>
                        <span className={style.won}>{isLoggedIn ? '원' : null}</span>
                      </p>
                    )}
                  </div>
                </div>
              )}

              <div className={style.total_price}>
                <p className={style.title}>
                  총 합계 <span className={style.vat}>(VAT 별도)</span>
                </p>

                <p className={style.price}>
                  {isSoldOut === false && (
                    <span className={style.num}>{isLoggedIn ? calculateTotalPrice() : null}</span>
                  )}
                  {isSoldOut && isLoggedIn && (
                    <span
                      className={style.num}
                      style={{
                        textDecoration: 'line-through',
                        color: colorList.darkGray,
                      }}
                    >
                      {calculateTotalPrice()}
                    </span>
                  )}
                  <span className={style.won}>{isLoggedIn ? '원' : null}</span>
                </p>
              </div>

              {isSoldOut === false && isLoggedIn && (
                <div className={style.order_method_box}>
                  <InputBox
                    inputType="radio"
                    htmlFor="payTypeTransfer"
                    inputWidth="20px"
                    containerWidth="fit-content"
                    containerMargin="20px 0"
                    inputValue="transfer"
                    inputChecked={payType === 'transfer'}
                    onChangeEvent={(e) => setPayType((cur) => (e.target.checked ? 'transfer' : ''))}
                  >
                    계좌이체
                  </InputBox>
                  <InputBox
                    inputType="radio"
                    htmlFor="payTypeCard"
                    inputWidth="20px"
                    containerWidth="fit-content"
                    containerMargin="20px 0"
                    inputValue="card"
                    inputChecked={payType === 'card'}
                    onChangeEvent={(e) => setPayType((cur) => (e.target.checked ? 'card' : ''))}
                  >
                    카드결제
                  </InputBox>
                </div>
              )}

              <div className={style.btn_list}>
                <BtnNormal
                  flex="1"
                  onClickEvent={addOrder}
                  disabled={isSoldOut}
                  border="1px solid #ddd"
                  fontSize={LARGE_SCREEN ? '18px' : '20px'}
                  padding={LARGE_SCREEN ? '15px 10px' : '20px'}
                  bgColor={colorList.white}
                  color={!isSoldOut ? colorList.dark : colorList.darkGray}
                  fontWeight="200"
                >
                  {isSoldOut === false && '바로구매'}
                  {isSoldOut && '품절'}
                </BtnNormal>
                <BtnNormal
                  flex="1"
                  fontSize={LARGE_SCREEN ? '18px' : '20px'}
                  padding={LARGE_SCREEN ? '15px 10px' : '20px'}
                  fontWeight="200"
                  onClickEvent={addCart}
                >
                  장바구니
                </BtnNormal>
              </div>
              <div style={{ marginTop: '20px', textAlign: 'right' }}></div>
            </div>
          </div>
        </div>
      </SectionLayout>

      {TABLET_SCREEN && (
        <BottomMenuProductDetail
          bottomMenuOptionValue={option ?? '0'}
          bottomOptionOnChange={optionCheckHandler}
          optionData={item?.data?.product_option_list}
          scrollPoint={SMALL_SCREEN ? toastMenuPointSmallScreen : toastMenuPoint}
          productName={item?.data?.name}
          price={!isLoggedIn ? '로그인을 해주세요.' : item?.data?.price}
          isSoldOut={isSoldOut}
          onClickMinus={decreaseOrderCount}
          onClickPlus={increaseOrderCount}
          onChangeValue={orderCount}
          onChangeCount={orderCountHandler}
          onClickBuy={addOrder}
          onClickCart={addCart}
          isPayTypeRadioBox={true}
          payTypeRadioValue={payType}
          payTypeRadioOnChange={setPayType}
          titleBoxFlex={EXTRA_SMALL_SCREEN ? '6' : undefined}
          radioLabelFontSize={EXTRA_SMALL_SCREEN ? '12px' : undefined}
          totalPriceSize={EXTRA_SMALL_SCREEN ? '22px' : undefined}
          totalFontSize={EXTRA_SMALL_SCREEN ? '16px' : undefined}
          isLoggedIn={isLoggedIn}
        />
      )}

      <div className={style.bold_line} />

      <SectionLayout padding={MEDIUM_SMALL_SCREEN ? '40px 20px' : undefined}>
        {!TABLET_SCREEN && (
          <FloatingMenu
            floatingOptionValue={option ?? '0'}
            floatingOptionOnChange={optionCheckHandler}
            optionData={item?.data?.product_option_list}
            productName={item?.data?.name}
            price={!isLoggedIn ? '로그인을 해주세요.' : item?.data?.price}
            isSoldOut={isSoldOut}
            onClickMinus={decreaseOrderCount}
            onClickPlus={increaseOrderCount}
            onChangeValue={orderCount}
            onChangeCount={orderCountHandler}
            onClickBuy={addOrder}
            onClickCart={addCart}
            isPayTypeRadioBox={true}
            payTypeRadioValue={payType}
            payTypeRadioOnChange={setPayType}
            backTop="120px"
            backRight="0px"
            stickyTop="50px"
            isLoggedIn={isLoggedIn}
          />
        )}
        <div className={style.detail_con}>
          <div className={style.item_head}>
            <p className={style.title}>상세정보</p>
            {item?.data?.product_attachment && (
              <a
                className={style.add_file_btn}
                href={item?.data?.product_attachment?.attachment_url}
                target=" _blank"
                rel="noopener noreferror"
              >
                첨부파일
              </a>
            )}
          </div>
          <div className={style.detail_content_wrap}>
            <TextEditor_Quill_ReadOnly
              value={item?.data?.content}
              margin={FULL_SCREEN ? '0' : '0 0 0 100px'}
            />
          </div>
        </div>
      </SectionLayout>
    </>
  );
}

export default ProductDetail;
