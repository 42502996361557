import axios from 'axios';

import { decodingCrypto, generateCrypto } from '../utils/crypto';

// netlify.toml
const PROXY =
  window.location.hostname === 'localhost'
    ? 'http://localhost:8080'
    : window.location.hostname === 'www.ilppang.com'
    ? '/prod'
    : '/dev';
const URL = `${PROXY}`;

// 요청인스턴스
const instance = axios.create({
  baseURL: URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// 요청 인터셉터 설정
// 첫번째 인자는 성공시, 두번째 인자는 실패시
instance.interceptors.request.use(
  (config) => {
    const accessToken = decodingCrypto(localStorage.getItem('token'));

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

// 응답 인터셉터 설정
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const tokenCheck = decodingCrypto(localStorage.getItem('token'));
    if (
      // 인증실패 status코드 401
      tokenCheck &&
      error.response.status === 401 &&
      error.config.url !== '/api/token/refresh' &&
      error.config.url !== '/api/member/login'
    ) {
      try {
        // 쿠키에 저장된 리프레시 토큰을 사용하여 새 액세스 토큰 발급
        const response = await instance.post('/api/token/refresh'); // 쿠키가 자동으로 포함되므로 별도의 토큰 전달이 필요하지 않음
        const newAccessToken = response.data.access_token;

        // 새 액세스 토큰 저장
        localStorage.setItem('token', generateCrypto(newAccessToken));

        // 실패한 요청 재전송
        const originalRequest = error.config;
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return instance(originalRequest);
      } catch (e) {
        localStorage.clear();
        window.location.href = '/pages/member/login';
      }
    }
    return Promise.reject(error);
  },
);

export default instance;
