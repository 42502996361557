import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  AiOutlineExclamationCircle,
  AiOutlineMinusSquare,
  AiOutlinePlusSquare,
} from 'react-icons/ai';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { endPoint, endPointMutation } from '../../../api/endPoint';
import style from '../../../assets/css/ProductWrite.module.scss';
import { colorList } from '../../../assets/style/theme.jsx';
import BtnNormal from '../../../components/BtnNormal.jsx';
import ErrorFetching from '../../../components/error/ErrorFetching';
import HeaderTitle from '../../../components/HeaderTitle.jsx';
import InputBox from '../../../components/InputBox.jsx';
import InputBoxFile from '../../../components/InputBoxFile.jsx';
import SectionLayout from '../../../components/layout/SectionLayout.jsx';
import { useMediaQueryCustom } from '../../../components/mediaQueryCustom/useMediaQueryCustom.js';
import ToolTipPopup from '../../../components/popup/ToolTipPopup.jsx';
import { usePopup } from '../../../components/popup/usePopup.js';
import SelectCustom from '../../../components/SelectCustom.jsx';
import SEOHelmet from '../../../components/seo/SEOHelmet';
import TextEditor_Quill from '../../../components/TextEditor_Quill';
import { FILE_SIZE } from '../../../constant/NUMBER_CONSTANT.js';
import { PRODUCT_TYPE, WRITE_TYPE } from '../../../constant/TYPE_CONSTANT';
import { queryKeys } from '../../../react-query/queryKeyConstant';
import { useMutationInstance } from '../../../react-query/useMutationInstance';
import { useQueryInstance } from '../../../react-query/useQueryInstance';
import { decodingCrypto } from '../../../utils/crypto';
import { IsLoggedIn } from '../../../utils/IsLoggedIn';
import { changeEnteredNumComma, commaInput, resultCommaRemove } from '../../../utils/numberComma';
import ValidateInput from '../../../utils/ValidateInput';

function ProductWrite() {
  const navigate = useNavigate();

  // 팝업창
  const { openPopup } = usePopup();

  // 수정시 파람스 얻기 -> 없다면 신규상품등록 모드로
  const { idx } = useParams();

  // 쿼리파람스 얻기 => 복사모드
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const copy = Boolean(params.get('copy'));

  // 로그인 여부 확인
  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  // 반응형 포인트
  const { MEDIUM_SMALL_SCREEN, SMALL_SCREEN, EXTRA_SMALL_SCREEN } = useMediaQueryCustom();

  // ------

  // 요청에 대한 리액트 쿼리 -------
  // 리액트쿼리 상태 보관에 직접 접근
  const queryClient = useQueryClient();

  // 카테고리 벨류 저장
  // 1depth
  const [selectedCategory1, setSelectedCategory1] = useState(null);
  // 2depth
  const [selectedCategory2, setSelectedCategory2] = useState(null);
  // 3depth
  const [selectedCategory3, setSelectedCategory3] = useState(null);

  // 셀렉터용 카테고리 데이터 가공
  // 셀렉터용 배열 1뎁스
  const [categoryArrFirst, setCategoryArrFirst] = useState(null);

  // 셀렉터용 배열 2뎁스
  const [categoryArrTwo, setCategoryArrTwo] = useState(null);

  // 셀렉터용 배열 3뎁스
  const [categoryArrThird, setCategoryArrThird] = useState([{ label: '- 분류 선택 -', value: '' }]);

  // react-query에서 캐싱 된 모든 카테고리 불러오기
  const { data, isError } = useQueryInstance({
    queryKey: [queryKeys.getProductCategoryList],
    apiMethod: 'get',
    apiEndPoint: endPoint.category.getProductCategoryList,
    onSuccess: ({ data_list }) => {
      // 1뎁스 처리
      if (data_list?.length > 0) {
        setCategoryArrFirst(() => [
          { label: '- 분류 선택 -', value: '' },
          ...data_list.map((el) => ({ label: el.name, value: el.category_idx })),
        ]);
      }
    },
  });

  // 2뎁스처리
  useEffect(() => {
    if (data?.data_list?.length > 0) {
      const firstFiter = selectedCategory1
        ? data.data_list
            .map((el) =>
              selectedCategory1 === el.category_idx && el.child_category_list
                ? el.child_category_list
                : null,
            )
            .filter((el) => el !== null)[0]
        : null;

      const twoNewArr = firstFiter
        ? firstFiter.map((el) => ({ label: el.name, value: el.category_idx }))
        : null;

      setCategoryArrTwo(() => {
        return [{ label: '- 분류 선택 -', value: '' }, ...(twoNewArr ? twoNewArr : [])];
      });
    }
  }, [selectedCategory1]);

  // 3뎁스처리
  useEffect(() => {
    if (data?.data_list?.length > 0) {
      const firstFiter = selectedCategory1
        ? data.data_list
            .map((el) =>
              selectedCategory1 === el.category_idx && el.child_category_list
                ? el.child_category_list
                : null,
            )
            .filter((el) => el !== null)[0]
        : null;

      const twoFilter = selectedCategory2
        ? firstFiter
            .map((el) =>
              selectedCategory2 === el.category_idx && el.child_category_list
                ? el.child_category_list
                : null,
            )
            .filter((el) => el !== null)[0]
        : null;
      const thirdNewArr = twoFilter
        ? twoFilter.map((el) => ({ label: el.name, value: el.category_idx }))
        : null;

      setCategoryArrThird(() => {
        return [{ label: '- 분류 선택 -', value: '' }, ...(thirdNewArr ? thirdNewArr : [])];
      });
    }
  }, [selectedCategory2]);

  // 수정용 ----
  // idx값이 있다면 요청되는 부분
  const { data: item, isError: itemIsError } = useQueryInstance({
    // idx값이 있고, ValidateInput이 true를 반환하는 충족일시 쿼리요청 실행
    queryEnable: ValidateInput(idx),
    queryKey: [queryKeys.product, idx],
    apiMethod: 'get',
    apiEndPoint: endPoint.product.getProduct,
    apiPathParams: idx,
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 0,
    cacheTime: 0,
  });

  // 언마운트시 이미지 삭제
  //mutation 이미지삭제 Post 요청

  const { mutate: removeIMGMutation } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.quill.removeUploadFile,
    onErrorFn: () => {
      return openPopup({
        title: '오류',
        content: '사진을 다시 업로드해주세요.',
      });
    },
    onSuccessFn: (_, variables) => {
      const { apiBody } = variables;
      // 쿼리 상태보관에서 삭제
      if (apiBody && apiBody?.length > 0) {
        const currentQuery = queryClient.getQueryData([queryKeys.productWrite]);
        if (currentQuery) {
          const updateQuery = currentQuery.filter(
            (el) => !apiBody.some((item) => item.url === el.url),
          );
          if (updateQuery.length > 0)
            return queryClient.setQueryData([queryKeys.productWrite], updateQuery);
          return queryClient.removeQueries([queryKeys.productWrite]);
        }
      }
    },
  });

  // 상품이름 데이터
  const productNameRef = useRef(null);
  // 규격 데이터
  const standardRef = useRef(null);
  // 단위 데이터
  const productUnitRef = useRef(null);
  // 용도 데이터
  const productUsageRef = useRef(null);
  // 원산지 데이터
  const madeByCountryRef = useRef(null);
  // 회사명 데이터
  const madeByCompanyRef = useRef(null);

  // 상품 옵션 타입 -- 재고랑 연관
  const [optionType, setOptionType] = useState(false);

  // 콤마제어
  const optionStockRef = useRef([]);
  const optionMainOrderQuantityRef = useRef([]);

  const [addOption, setAddOption] = useState([
    { name: '', stock_quantity: '', min_order_quantity: '' },
  ]);

  // 옵션필드 추가
  const addOptionInput = (index) => {
    if (addOption.length < 10)
      return setAddOption((cur) => [
        ...cur.slice(0, index + 1),
        { name: '', stock_quantity: '', min_order_quantity: '' },
        ...cur.slice(index + 1),
      ]);

    return openPopup({ title: '안내', content: '옵션은 최대 10개까지 가능합니다.' });
  };

  // 옵션필드 삭제
  const removeOptionInput = (index) => {
    setAddOption((cur) => [...cur.slice(0, index), ...cur.slice(index + 1)]);
  };

  // 옵션값 입력
  const optionInputHandler = useCallback((value, index, type) => {
    //name
    if (type === 1) {
      const nameValue = value.target.value;

      if (nameValue.length <= 12) {
        setAddOption((cur) => {
          let copy = [...cur];
          copy[index] = { ...cur[index], name: nameValue };

          return copy;
        });
      }
    }

    // stock
    if (type === 2 && resultCommaRemove(value) <= 1000) {
      setAddOption((cur) => {
        let copy = [...cur];
        copy[index] = { ...cur[index], stock_quantity: value };

        return copy;
      });
    }

    // min_stock
    if (type === 3 && resultCommaRemove(value) <= 1000) {
      setAddOption((cur) => {
        let copy = [...cur];
        copy[index] = { ...cur[index], min_order_quantity: value };

        return copy;
      });
    }
  }, []);

  // 재고 데이터
  const stockRef = useRef(null);
  const minOrderQuantityRef = useRef(null);
  // 가격 데이터 (옵션x)
  const priceRef = useRef(null);
  // b2b가격
  const b2bPriceRef = useRef();
  // b2c가격
  const b2cPriceRef = useRef();

  const [img1File, setImg1File] = useState('');
  const [img1FileName, setImg1FileName] = useState('');
  const [img1FileIdx, setImg1FileIdx] = useState('');
  // 프레뷰blob:url 저장용
  const [img1FilePreview, setImg1FilePreview] = useState(null);

  const [img2File, setImg2File] = useState('');
  const [img2FileName, setImg2FileName] = useState('');
  const [img2FileIdx, setImg2FileIdx] = useState('');
  // 프레뷰blob:url 저장용
  const [img2FilePreview, setImg2FilePreview] = useState(null);

  const [img3File, setImg3File] = useState('');
  const [img3FileName, setImg3FileName] = useState('');
  const [img3FileIdx, setImg3FileIdx] = useState('');
  // 프레뷰blob:url 저장용
  const [img3FilePreview, setImg3FilePreview] = useState(null);

  const [uploadFileName, setUploadFileName] = useState('');
  const [uploadFile, setUploadFile] = useState('');
  const [uploadFileIdx, setUploadFileIdx] = useState('');
  // const [isAvailable, setIsAvailable] = useState(1);

  // 수정시 content내부 img url 셋
  const [preContentImagesUrl, setPreContentImagesUrl] = useState([]);

  // 이미지 인풋태그 제어용 Ref
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const uploadFileInputRef = useRef(null);

  // 인풋태그 클릭on
  const openFilePicker1 = () => {
    fileInputRef1.current.click();
  };
  const openFilePicker2 = () => {
    // 1번 이미지 네임이 없다면, 1번 등록 안내 후 종료
    if (img1FileName === '')
      return openPopup({ title: '안내', content: `'제품이미지1'를 등록해주세요.` });

    fileInputRef2.current.click();
  };
  const openFilePicker3 = () => {
    // 2번 이미지 네임이 없다면, 2번 등록 안내 후 종료
    if (img2FileName === '')
      return openPopup({ title: '안내', content: `'제품이미지2'를 등록해주세요.` });

    fileInputRef3.current.click();
  };
  const openUploadFilePicker = () => {
    uploadFileInputRef.current.click();
  };

  // Quill에디터
  // 텍스트 데이터
  const [editorData, setEditorData] = useState('<p><br></p>');

  // send체크
  const [sendCheck, setSendCheck] = useState(false);
  // useRef를 사용하여 클린업함수 제어
  const sendCheckRef = useRef(false);

  // 옵션 빈인풋 체크 함수
  const optionCheck = useCallback((options) => {
    let result = true;

    options.some((el) => {
      if (el.name === '' || !el.stock_quantity || resultCommaRemove(el.stock_quantity) < 1) {
        result = false;
        return true;
      }
      return false;
    });

    return result;
  }, []);

  // 옵션 최소주문수량 체크 함수
  const optionMinQuantityCheck = useCallback((options) => {
    let result = true;

    options.some((el) => {
      if (
        resultCommaRemove(el.stock_quantity) <
        (!el.min_order_quantity ? 1 : resultCommaRemove(el.min_order_quantity))
      ) {
        result = false;
        return true;
      }
      return false;
    });

    return result;
  }, []);

  // check
  const validateProductWrite = () => {
    let check = false;

    if (!ValidateInput(selectedCategory1) || selectedCategory1 === 0) {
      openPopup({ title: '안내', content: '분류1을 선택해주세요.' });
      return;
    }
    if (!ValidateInput(selectedCategory2) || selectedCategory2 === 0) {
      openPopup({ title: '안내', content: '분류2을 선택해주세요.' });
      return;
    }
    if (!ValidateInput(selectedCategory3) || selectedCategory3 === 0) {
      openPopup({ title: '안내', content: '분류3을 선택해주세요.' });
      return;
    }
    if (!productNameRef?.current?.value) {
      openPopup({ title: '안내', content: '상품명을 입력해주세요.' });
      return;
    }
    if (!standardRef?.current?.value) {
      openPopup({ title: '안내', content: '규격을 입력해주세요.' });
      return;
    }
    if (!productUnitRef?.current?.value) {
      openPopup({ title: '안내', content: '단위을 입력해주세요.' });
      return;
    }
    if (!productUsageRef?.current?.value) {
      openPopup({ title: '안내', content: '용도 입력해주세요.' });
      return;
    }
    if (!optionType && !stockRef?.current?.value) {
      openPopup({ title: '안내', content: '재고수량을 입력해주세요.' });
      return;
    }
    if (!optionType && resultCommaRemove(stockRef?.current?.value) < 1) {
      openPopup({ title: '안내', content: '재고수량은 1 이상 입력해주세요.' });
      return;
    }
    if (
      !optionType &&
      resultCommaRemove(stockRef?.current?.value) <
        resultCommaRemove(minOrderQuantityRef?.current?.value)
    ) {
      openPopup({ title: '안내', content: `"최소주문수량"이 '재고수량'을 초과합니다.` });
      return;
    }
    if (optionType && !optionCheck(addOption)) {
      openPopup({ title: '안내', content: `옵션항목의 '옵션명'과 '재고수량'을 입력해주세요.` });
      return;
    }
    if (optionType && !optionMinQuantityCheck(addOption)) {
      openPopup({
        title: '안내',
        content: `옵션항목 중 \n '최소주문수량'이 '재고수량'을 초과하는 것이 있습니다.`,
      });
      return;
    }
    if (!priceRef?.current?.value) {
      openPopup({ title: '안내', content: '도매가를 입력해주세요.' });
      return;
    }
    if (resultCommaRemove(priceRef?.current?.value) < 100) {
      openPopup({ title: '안내', content: '도매가는 100원 이상 입력해주세요.' });
      return;
    }
    if (!b2bPriceRef?.current?.value) {
      openPopup({ title: '안내', content: 'B2B 가격을 입력해주세요.' });
      return;
    }
    if (!b2cPriceRef?.current?.value) {
      openPopup({ title: '안내', content: 'B2C 가격을 입력해주세요.' });
      return;
    }
    if (!img1FileName) {
      openPopup({ title: '안내', content: '제품의 메인사진을 등록해주세요.' });
      return;
    }

    check = true;

    return check;
  };

  // 상품 수정 및 에디터 요청
  // mutation 요청--
  const { mutate: onSendProductMutation } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint:
      idx && !copy ? endPointMutation.product.editProduct : endPointMutation.product.saveProduct,
    apiMultipartPost: true,
    onErrorFn: (error) => {
      const errorData = error.response.data;
      openPopup({
        title: '안내',
        content: errorData.message,
      });
    },
    onSuccessFn: () => {
      queryClient.refetchQueries([queryKeys.productList], { force: true });
      queryClient.refetchQueries([queryKeys.myProductList], { force: true });
      setSendCheck(true);
      sendCheckRef.current = true;
    },
  });

  // 수정시 본문 이미지 URL체크용 함수
  const checkContentImg = useCallback(
    (idx) => {
      // 수정모드 아니라면 기존 로직으로 끝
      if (!idx || copy) return queryClient.getQueryData([queryKeys.productWrite]);

      // 수정모드인데, 이미지 수정에 대한 흔적이 없다면 기존 프로퍼티를 그대로 넘김
      const deleteImageUrlEdit = queryClient.getQueryData([queryKeys.deleteImageUrlEdit]);
      const productWrite = queryClient.getQueryData([queryKeys.productWrite]);
      if (!deleteImageUrlEdit && !productWrite) return preContentImagesUrl;

      // 지우거나 추가한 흔적이 있다면 아래 진행
      let newContentImage = [];

      if (preContentImagesUrl) {
        !deleteImageUrlEdit
          ? (newContentImage = preContentImagesUrl)
          : (newContentImage = preContentImagesUrl.filter(
              (el) => !deleteImageUrlEdit.some((del) => el.url === del.url),
            ));
      }

      if (productWrite && productWrite.length > 0) {
        productWrite.forEach((el) => newContentImage.push(el));
      }

      return newContentImage;
    },
    [preContentImagesUrl],
  );

  // 요청 핸들 // 더블클릭 방지
  const onSendProduct = () => {
    if (!validateProductWrite()) {
      return;
    }

    const properties = {
      category1: selectedCategory1,
      category2: selectedCategory2,
      category3: selectedCategory3,
      made_by_country: madeByCountryRef?.current?.value,
      made_by_company: madeByCompanyRef?.current?.value,
      b2b_price: resultCommaRemove(b2bPriceRef?.current?.value),
      b2c_price: resultCommaRemove(b2cPriceRef?.current?.value),
      contentImagesUrl: checkContentImg(idx),
    };

    const updateImgFileIdxList = [];
    const deleteImgFileIdxList = [];

    const fileDataList = [];

    if (ValidateInput(img1File)) {
      fileDataList.push(img1File);
      if (ValidateInput(idx)) {
        if (ValidateInput(img1FileIdx)) {
          updateImgFileIdxList.push(img1FileIdx);
        } else {
          updateImgFileIdxList.push(-1);
        }
      }
    } else {
      if (ValidateInput(img1FileIdx) && !ValidateInput(img1FileName)) {
        deleteImgFileIdxList.push(img1FileIdx);
      }
    }

    if (ValidateInput(img2File)) {
      fileDataList.push(img2File);
      if (ValidateInput(idx)) {
        if (ValidateInput(img2FileIdx)) {
          updateImgFileIdxList.push(img2FileIdx);
        } else {
          updateImgFileIdxList.push(-1);
        }
      }
    } else {
      if (ValidateInput(img2FileIdx) && !ValidateInput(img2FileName)) {
        deleteImgFileIdxList.push(img2FileIdx);
      }
    }

    if (ValidateInput(img3File)) {
      fileDataList.push(img3File);
      if (ValidateInput(idx)) {
        if (ValidateInput(img3FileIdx)) {
          updateImgFileIdxList.push(img3FileIdx);
        } else {
          updateImgFileIdxList.push(-1);
        }
      }
    } else {
      if (ValidateInput(img3FileIdx) && !ValidateInput(img3FileName)) {
        deleteImgFileIdxList.push(img3FileIdx);
      }
    }

    const minOrderCheck =
      !minOrderQuantityRef?.current?.value || Number(minOrderQuantityRef?.current?.value) === 0
        ? 1
        : resultCommaRemove(minOrderQuantityRef?.current?.value);

    const data = {
      name: productNameRef?.current?.value,
      standard: standardRef?.current?.value,
      product_unit: productUnitRef?.current?.value,
      product_usage: productUsageRef?.current?.value,
      content: editorData,
      price: resultCommaRemove(priceRef?.current?.value),
      stock_quantity: !optionType ? resultCommaRemove(stockRef?.current?.value) : null,
      min_order_quantity: !optionType ? minOrderCheck : null,
      product_category_idx: selectedCategory3,
      product_type: PRODUCT_TYPE.GENERAL,
      properties: JSON.stringify(properties),
      is_available: 1,
    };

    const formData = new FormData();
    formData.append('product', JSON.stringify(data));

    const productOptionList = optionType
      ? addOption.map((el) => ({
          ...el,
          stock_quantity: resultCommaRemove(el.stock_quantity),
          min_order_quantity:
            resultCommaRemove(el.min_order_quantity) === 0
              ? 1
              : resultCommaRemove(el.min_order_quantity),
        }))
      : null;

    formData.append('product_option_list', JSON.stringify(productOptionList));

    if (fileDataList.length > 0) {
      fileDataList.forEach((file, index) => {
        formData.append('img_upload_file_list', file);
      });
    }
    if (ValidateInput(uploadFile)) {
      formData.append('upload_file', uploadFile);
    }

    if (ValidateInput(idx)) {
      formData.append('product_idx', idx);
      if (updateImgFileIdxList.length > 0) {
        formData.append('update_img_file_idx_list', updateImgFileIdxList);
      }
      if (deleteImgFileIdxList.length > 0) {
        formData.append('delete_img_file_idx_list', deleteImgFileIdxList);
      }
      if (!ValidateInput(uploadFileName) && ValidateInput(uploadFileIdx)) {
        // 첨부파일 삭제할껀지 여부
        const deleteUploadFileValue = 1;
        formData.append('delete_upload_file_value', deleteUploadFileValue);
      }
    }

    onSendProductMutation({ apiBody: formData });
  };

  // 수정시 이미지 삭제 함수 ------
  const { mutate: deleteEditimageMutation } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.quill.removeUploadFile,
    onErrorFn: (error) => {
      console.error(error);
    },
    onSuccessFn: () => {
      queryClient.removeQueries([queryKeys.deleteImageUrlEdit]);
    },
  });

  const deleteEditImageAPI = async () => {
    const deleteImageUrl = await queryClient.getQueryData([queryKeys.deleteImageUrlEdit]);

    if (!deleteImageUrl) return;
    // deleteImageUrl가 있다면 아래 요청 진행
    deleteEditimageMutation({ apiBody: deleteImageUrl });
  };

  useEffect(() => {
    // 샌드요청이면 아래 진행,
    if (sendCheck) {
      if (idx && !copy) {
        deleteEditImageAPI();
      }
      navigate('/pages/member/mypage/product_list');
      window.scrollTo(0, 0);
      return;
    }
    // --------------

    // 에디터 이미지 언마운트시 설정(quill컴포넌트에서 제어가 불가하여 부모 컴포넌트에서 처리)
    // 아니라면 언마운트시 이미지 s3에서 삭제
    return () => {
      if (!sendCheckRef.current) {
        queryClient.removeQueries([queryKeys.deleteImageUrlEdit]);
        const removeURLs = queryClient.getQueryData([queryKeys.productWrite]);
        if (removeURLs) {
          removeIMGMutation({ apiBody: removeURLs });
        }
      } else {
        queryClient.removeQueries([queryKeys.productWrite]);
      }
    };
  }, [sendCheckRef.current]);

  // 이미지 인풋 관련 setter
  const settersFileName = {
    1: setImg1FileName,
    2: setImg2FileName,
    3: setImg3FileName,
    4: setUploadFileName,
  };
  const settersRef = {
    1: fileInputRef1,
    2: fileInputRef2,
    3: fileInputRef3,
    4: uploadFileInputRef,
  };

  const settersImgFile = {
    1: setImg1File,
    2: setImg2File,
    3: setImg3File,
    4: setUploadFile,
  };

  const setterImgFilePreview = {
    1: setImg1FilePreview,
    2: setImg2FilePreview,
    3: setImg3FilePreview,
  };

  // 이미지 초기화 -----------------
  const deleteImage = useCallback(
    (index) => {
      switch (index) {
        case 1:
          if (img2FileName !== '' || img3FileName !== '') {
            openPopup({ title: '안내', content: `하위 이미지들부터 순차적으로 삭제해주세요.` });
            break;
          } else {
            setterImgFilePreview[index](null);
            settersFileName[index](''); // 파일명 초기화
            settersRef[index].current.value = ''; // input file 초기화
            settersImgFile[index]('');
            break;
          }
        case 2:
          if (img3FileName !== '') {
            openPopup({ title: '안내', content: `하위 이미지들부터 순차적으로 삭제해주세요.` });
            break;
          } else {
            setterImgFilePreview[index](null);
            settersFileName[index](''); // 파일명 초기화
            settersRef[index].current.value = ''; // input file 초기화
            settersImgFile[index]('');
            break;
          }
        case 3:
          setterImgFilePreview[index](null);
          settersFileName[index](''); // 파일명 초기화
          settersRef[index].current.value = ''; // input file 초기화
          settersImgFile[index]('');
          break;
      }
    },
    [img1FileName, img2FileName, img3FileName],
  );
  // ----------------------------------

  const deleteFile = useCallback((index) => {
    settersFileName[index](''); // 파일명 초기화
    settersRef[index].current.value = ''; // input file 초기화
    settersImgFile[index]('');
  }, []);

  // 이미지 받는 인풋 핸들러 -------------------
  const onFileChange = useCallback((e, index) => {
    const file = e.target.files[0];

    if (!file) return;

    if (
      file &&
      index !== 4 &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/gif' &&
      file.type !== 'image/png'
    ) {
      openPopup({
        title: '안내',
        content: `사진파일만 업로드 가능합니다.\n( jpeg, jpg, png, gif )`,
      });
      settersRef[index].current.value = '';
      setterImgFilePreview[index](null);
      settersImgFile[index]('');
      settersFileName[index]('');
      return;
    }

    if (file && index === 4 && file.type !== 'application/pdf') {
      openPopup({
        title: '안내',
        content: `PDF파일만 가능합니다.`,
      });
      settersRef[index].current.value = '';
      settersImgFile[index]('');
      settersFileName[index]('');
      return;
    }

    if (
      file &&
      file.size > (index === 4 ? FILE_SIZE.MAX_UPLOAD_FILE_SIZE : FILE_SIZE.MAX_IMAGE_FILE_SIZE)
    ) {
      openPopup({
        title: '안내',
        content: `파일 크기가 너무 큽니다. ${
          index === 4 ? '5MB' : '3MB'
        }이하의 이미지파일을 입력해주세요.`,
      });
      settersRef[index].current.value = '';
      return;
    }

    if (index !== 4) {
      const previewBlob = URL.createObjectURL(file);
      setterImgFilePreview[index](previewBlob);
      settersImgFile[index](file);
      settersFileName[index](file.name);
      e.preventDefault();
    } else {
      settersImgFile[index](file);
      settersFileName[index](file.name);
      e.preventDefault();
    }
  }, []);

  // 수정하기전 데이터 세팅
  useEffect(() => {
    if (!item?.data || !idx) return;

    // json파싱할 데이터 파싱 후 아래에서 사용
    const properties = JSON.parse(item.data.properties);

    if (properties.contentImagesUrl) {
      setPreContentImagesUrl(properties.contentImagesUrl);
    }

    // 카테고리 set
    if (item.data.product_category_idx) {
      setSelectedCategory1(properties.category1);
      setSelectedCategory2(properties.category2 || null);
      setSelectedCategory3(properties.category3 || null);
    }

    // 다른 인풋 set
    if (item.data.name && !copy) {
      productNameRef.current.value = item.data.name;
    }
    if (item.data.standard) {
      standardRef.current.value = item.data.standard;
    }
    if (item.data.product_unit) {
      productUnitRef.current.value = item.data.product_unit;
    }

    if (item.data.product_usage) {
      productUsageRef.current.value = item.data.product_usage;
    }
    if (item.data.properties) {
      if (properties.made_by_country) {
        madeByCountryRef.current.value = properties.made_by_country;
      }
      if (properties.made_by_company) {
        madeByCompanyRef.current.value = properties.made_by_company;
      }
    }
    if (item.data.stock_quantity) {
      stockRef.current.value = changeEnteredNumComma(item.data.stock_quantity);
    }
    if (item.data.min_order_quantity) {
      minOrderQuantityRef.current.value = changeEnteredNumComma(item.data.min_order_quantity);
    }
    if (item.data.price) {
      priceRef.current.value = changeEnteredNumComma(item.data.price);
    }
    if (properties.b2b_price) {
      b2bPriceRef.current.value = changeEnteredNumComma(properties.b2b_price);
    }
    if (properties.b2c_price) {
      b2cPriceRef.current.value = changeEnteredNumComma(properties.b2c_price);
    }
    if (item.data.content) {
      setEditorData(item.data.content);
    }
    // 옵션 사항
    if (item.data?.product_option_list?.length > 0) {
      setOptionType(true);
      setAddOption(() => {
        return item.data?.product_option_list.map((el) => ({
          ...el,
          stock_quantity: el.stock_quantity?.toLocaleString('ko-KR'),
          min_order_quantity: el.min_order_quantity?.toLocaleString('ko-KR'),
        }));
      });
    }

    // setIsAvailable(item.is_available);

    if (item.data.product_img_list && !copy) {
      const productImgListValue = item.data.product_img_list;

      productImgListValue.forEach((el, index) => {
        switch (index) {
          case 0:
            setImg1FileName(el.img_original_name);
            setImg1FilePreview(el.img_url);
            setImg1FileIdx(el.product_img_idx);
            break;
          case 1:
            setImg2FileName(el.img_original_name);
            setImg2FilePreview(el.img_url);
            setImg2FileIdx(el.product_img_idx);
            break;
          case 2:
            setImg3FileName(el.img_original_name);
            setImg3FilePreview(el.img_url);
            setImg3FileIdx(el.product_img_idx);
            break;
          default:
            break;
        }
      });
    }

    if (item.data.product_attachment && !copy) {
      setUploadFileName(item.data.product_attachment.attachment_original_name);
      setUploadFileIdx(item.data.product_attachment.product_attachment_idx);
    }
  }, [item?.data, idx, copy]);

  const [popupActive, setPopupActive] = useState(false);

  // 팝업 안내문
  useEffect(() => {
    if (!idx || copy) {
      setPopupActive(true);
    }

    const popupTime = setTimeout(() => {
      setPopupActive(false);
    }, 5000);

    return () => {
      clearTimeout(popupTime);
    };
  }, [idx, copy]);

  if (isError || itemIsError) return <ErrorFetching isError={isError || itemIsError} />;

  return (
    <>
      {idx && (
        <SEOHelmet
          title={`일빵 | ${!copy ? '상품 정보 수정' : '상품 복사 등록'}`}
          contentTitle={!copy ? '상품 정보 수정' : '상품 복사 등록'}
          description={!copy ? '등록한 상품 수정하는 페이지' : '상품 복사하여 신규 등록하는 페이지'}
          keywords={!copy ? '일빵, MRO, 상품, 수정' : '일빵, MRO, 상품, 등록'}
        />
      )}

      <ToolTipPopup
        active={popupActive}
        textAlign="center"
        content={
          <>
            &nbsp; 도매거래는 조합원들을 위한 서비스입니다. <br /> 조합의 일빵 가격정책을 참고하시어
            <br />
            <span style={{ fontWeight: '700' }}>'도매가격'</span>으로 올려주시길 바랍니다.
            <br />
            <br />
            <span style={{ fontSize: '14px', color: '#e70000', textDecoration: 'underline' }}>
              *등록시 수수료가 포함된 가격으로 등록됩니다.
            </span>
            <br />
            <span style={{ fontSize: '12px', color: '#e70000', textDecoration: 'underline' }}>
              (등록가격 + 4.8%(플랫폼수수료 + PG수수료) 합산)
            </span>
          </>
        }
      />

      <SectionLayout
        maxWidth="900px"
        padding={MEDIUM_SMALL_SCREEN ? '70px 19px 0px 19px' : '70px 50px 0px 50px'}
      >
        <HeaderTitle borderBottom="none" conJustfyContent="center" fontWeight="500" fontSize="24px">
          <p style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {`도매판매 제품${idx && !copy ? ' 수정' : idx && copy ? ' 복사 등록' : '등록'} `}
            <AiOutlineExclamationCircle
              size={20}
              color="#bd0000"
              style={{ cursor: 'pointer' }}
              onMouseOver={() => setPopupActive(true)}
              onMouseLeave={() => setPopupActive(false)}
            />
          </p>
        </HeaderTitle>
        <div style={{ position: 'relative', width: '100%', marginBottom: '15px' }}>
          <p style={{ fontSize: '14px', padding: '10px 0' }}>
            분류1 <span style={{ color: 'rgb(238, 106, 123)' }}>*</span>
          </p>
          <SelectCustom
            data={categoryArrFirst || [{ label: '- 분류 선택 -', value: '' }]}
            value={selectedCategory1 || ''}
            onChangeEvent={(e) => {
              const newValue = Number(e.target.value);
              setSelectedCategory1(newValue);
              setSelectedCategory2(null); // 분류1이 바뀌면 분류2 초기화
              setSelectedCategory3(null); // 분류1이 바뀌면 분류3도 초기화
            }}
            maxWidth="100%"
          />
        </div>

        <div style={{ position: 'relative', width: '100%', marginBottom: '15px' }}>
          <p style={{ fontSize: '14px', padding: '10px 0' }}>
            분류2 <span style={{ color: 'rgb(238, 106, 123)' }}>*</span>
          </p>
          <SelectCustom
            data={categoryArrTwo || [{ label: '- 분류 선택 -', value: '' }]}
            value={selectedCategory2 || ''}
            onChangeEvent={(e) => {
              const newValue = Number(e.target.value);
              setSelectedCategory2(newValue);
              setSelectedCategory3(null); // 분류2가 바뀌면 분류3 초기화
            }}
            maxWidth="100%"
          />
        </div>

        <div style={{ position: 'relative', width: '100%', marginBottom: '15px' }}>
          <p style={{ fontSize: '14px', padding: '10px 0' }}>
            분류3 <span style={{ color: 'rgb(238, 106, 123)' }}>*</span>
          </p>
          <SelectCustom
            data={categoryArrThird || [{ label: '- 분류 선택 -', value: '' }]}
            value={selectedCategory3 || ''}
            onChangeEvent={(e) => setSelectedCategory3(Number(e.target.value))}
            maxWidth="100%"
          />
        </div>

        <InputBox
          inputFontSize="16px"
          isRequiredMark
          inputDisabled
          inputValue={decodingCrypto(localStorage.getItem('member_name'))}
        >
          작성자
        </InputBox>

        <InputBox inputRef={productNameRef} inputFontSize="16px" isRequiredMark>
          상품명
        </InputBox>

        <InputBox inputRef={standardRef} inputFontSize="16px" isRequiredMark>
          규격
        </InputBox>

        <InputBox inputRef={productUnitRef} inputFontSize="16px" isRequiredMark>
          단위
        </InputBox>

        <InputBox inputRef={productUsageRef} inputFontSize="16px" isRequiredMark>
          용도
        </InputBox>

        <InputBox inputRef={madeByCountryRef} inputFontSize="16px">
          원산지
        </InputBox>

        <InputBox inputRef={madeByCompanyRef} inputFontSize="16px">
          제조업체
        </InputBox>

        {/* ----옵션----- */}

        <div className={style.option_box}>
          <div className={style.option_header}>
            <p style={{ fontSize: '14px' }}>
              옵션 <span className={style.required}> *</span>
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <InputBox
                inputType="radio"
                htmlFor="optionDefault"
                inputWidth="20px"
                inputValue={false}
                justifyContent="center"
                inputChecked={!optionType}
                onChangeEvent={(e) =>
                  setOptionType(() => {
                    if (e.target.checked) {
                      setAddOption([{ name: '', stock_quantity: '', min_order_quantity: '' }]);
                      return false;
                    }
                  })
                }
              >
                단일상품
              </InputBox>
              <InputBox
                inputType="radio"
                htmlFor="addOption"
                inputWidth="20px"
                inputValue="addOption"
                justifyContent="center"
                inputChecked={optionType}
                onChangeEvent={(e) =>
                  setOptionType(() => {
                    if (e.target.checked) {
                      stockRef.current.value = '';
                      minOrderQuantityRef.current.value = '';
                      return true;
                    }
                  })
                }
              >
                옵션추가
              </InputBox>
            </div>
          </div>

          {optionType &&
            addOption?.length !== 0 &&
            addOption?.map((_, index) => (
              <div className={style.option_container} key={`first_${index}`}>
                <div
                  className={style.option_input_title_box}
                  style={{
                    gap: MEDIUM_SMALL_SCREEN ? '1px' : undefined,
                    fontSize: EXTRA_SMALL_SCREEN ? '14px' : undefined,
                  }}
                >
                  <p>옵션명</p>
                  <p>재고수량</p>
                  <p>최소주문수량</p>
                  <p></p>
                </div>
                <div
                  className={style.option_input_box}
                  style={{
                    gap: MEDIUM_SMALL_SCREEN ? '1px' : undefined,
                    fontSize: EXTRA_SMALL_SCREEN ? '14px' : undefined,
                  }}
                >
                  <input
                    placeholder="최대 12자"
                    value={addOption[index]?.name}
                    onChange={(e) => optionInputHandler(e, index, 1)}
                  />
                  <input
                    placeholder="최대 1,000개"
                    value={addOption[index]?.stock_quantity}
                    onChange={(e) =>
                      commaInput(
                        e,
                        (newValue) => optionInputHandler(newValue, index, 2),
                        optionStockRef?.current[index],
                      )
                    }
                    ref={(dom) => (optionStockRef.current[index] = dom)}
                  />
                  <input
                    placeholder="미입력시 1개"
                    value={addOption[index]?.min_order_quantity}
                    onChange={(e) =>
                      commaInput(
                        e,
                        (newValue) => optionInputHandler(newValue, index, 3),
                        optionMainOrderQuantityRef?.current[index],
                      )
                    }
                    ref={(dom) => (optionMainOrderQuantityRef.current[index] = dom)}
                  />
                  <div className={style.plus_minus_box}>
                    <AiOutlinePlusSquare
                      size={35}
                      className={style.plus_icon}
                      onClick={() => addOptionInput(index)}
                    />
                    {addOption?.length !== 1 && (
                      <AiOutlineMinusSquare
                        size={35}
                        className={style.plus_icon}
                        onClick={() => removeOptionInput(index)}
                      />
                    )}
                  </div>
                </div>
                {resultCommaRemove(addOption[index]?.stock_quantity) <
                  resultCommaRemove(addOption[index]?.min_order_quantity) && (
                  <p
                    style={{
                      fontSize: '14px',
                      textAlign: 'center',
                      color: '#e70000',
                      marginBottom: '5px',
                    }}
                  >
                    * !최소주문수량이 재고수량을 초과했습니다! *
                  </p>
                )}
                {(resultCommaRemove(addOption[index]?.stock_quantity) === 1000 ||
                  resultCommaRemove(addOption[index]?.min_order_quantity) === 100) && (
                  <p style={{ fontSize: '14px', textAlign: 'center', color: '#000000' }}>
                    * 최대 재고량은 1,000개 입니다 *
                  </p>
                )}
              </div>
            ))}
        </div>

        {/* ----- */}

        <InputBox
          inputRef={stockRef}
          onChangeEvent={(e) =>
            commaInput(
              e,
              (newValue) => {
                stockRef.current.value = newValue;
              },
              stockRef?.current,
            )
          }
          inputFontSize="16px"
          isRequiredMark
          inputDisabled={optionType}
        >
          재고수량
        </InputBox>

        <InputBox
          inputRef={minOrderQuantityRef}
          onChangeEvent={(e) =>
            commaInput(
              e,
              (newValue) => {
                minOrderQuantityRef.current.value = newValue;
              },
              minOrderQuantityRef?.current,
            )
          }
          inputFontSize="16px"
          isRequiredMark
          inputDisabled={optionType}
          placeholder="미입력시 최소 주문수량은 1개 입니다."
        >
          최소 주문수량
        </InputBox>

        <InputBox
          inputRef={priceRef}
          inputFontSize="16px"
          isRequiredMark
          onChangeEvent={(e) =>
            commaInput(
              e,
              (newValue) => {
                priceRef.current.value = newValue;
              },
              priceRef?.current,
            )
          }
        >
          도매가 (일빵)
        </InputBox>

        <InputBox
          inputRef={b2bPriceRef}
          inputFontSize="16px"
          isRequiredMark
          onChangeEvent={(e) =>
            commaInput(
              e,
              (newValue) => {
                b2bPriceRef.current.value = newValue;
              },
              b2bPriceRef?.current,
            )
          }
        >
          B2B 가격
        </InputBox>

        <InputBox
          inputRef={b2cPriceRef}
          inputFontSize="16px"
          isRequiredMark
          onChangeEvent={(e) =>
            commaInput(
              e,
              (newValue) => {
                b2cPriceRef.current.value = newValue;
              },
              b2cPriceRef?.current,
            )
          }
        >
          B2C 가격
        </InputBox>

        <div style={{ position: 'relative', width: '100%', marginBottom: '15px' }}>
          <p style={{ fontSize: '14px', padding: '10px 0' }}>
            상세설명 <span style={{ color: 'rgb(238, 106, 123)' }}>*</span>
          </p>
          <TextEditor_Quill
            value={editorData}
            onChangeEvent={setEditorData}
            idx={idx}
            copy={copy}
            queryKey={queryKeys.productWrite}
            typeCode={WRITE_TYPE.PRODUCT_WRITE}
          />
        </div>

        <InputBoxFile
          isRequiered
          placeHolderText={
            SMALL_SCREEN
              ? 'jpg, jpeg, png, gif 파일(최대 3MB)'
              : 'jpg, jpeg, png, gif 파일(최대 3MB)만 업로드 가능합니다.'
          }
          placeHolderFontSize={EXTRA_SMALL_SCREEN ? '13px' : undefined}
          fileName={img1FileName}
          inputRef={fileInputRef1}
          onChangeEvent={(e) => onFileChange(e, 1)}
          closeOnClickEvent={() => deleteImage(1)}
          addFileOnClickEvent={openFilePicker1}
          previewImgSrc={img1FilePreview}
          btnFontSize={SMALL_SCREEN ? '12px' : undefined}
          btnPadding={SMALL_SCREEN ? '10px 12px' : undefined}
        >
          제품이미지1 (메인 이미지)
        </InputBoxFile>

        <InputBoxFile
          placeHolderText={
            SMALL_SCREEN
              ? 'jpg, jpeg, png, gif 파일(최대 3MB)'
              : 'jpg, jpeg, png, gif 파일(최대 3MB)만 업로드 가능합니다.'
          }
          placeHolderFontSize={EXTRA_SMALL_SCREEN ? '13px' : undefined}
          fileName={img2FileName}
          inputRef={fileInputRef2}
          onChangeEvent={(e) => onFileChange(e, 2)}
          closeOnClickEvent={() => deleteImage(2)}
          addFileOnClickEvent={openFilePicker2}
          previewImgSrc={img2FilePreview}
          btnFontSize={SMALL_SCREEN ? '12px' : undefined}
          btnPadding={SMALL_SCREEN ? '10px 12px' : undefined}
        >
          제품이미지2
        </InputBoxFile>

        <InputBoxFile
          placeHolderText={
            SMALL_SCREEN
              ? 'jpg, jpeg, png, gif 파일(최대 3MB)'
              : 'jpg, jpeg, png, gif 파일(최대 3MB)만 업로드 가능합니다.'
          }
          placeHolderFontSize={EXTRA_SMALL_SCREEN ? '13px' : undefined}
          fileName={img3FileName}
          inputRef={fileInputRef3}
          onChangeEvent={(e) => onFileChange(e, 3)}
          closeOnClickEvent={() => deleteImage(3)}
          addFileOnClickEvent={openFilePicker3}
          previewImgSrc={img3FilePreview}
          btnFontSize={SMALL_SCREEN ? '12px' : undefined}
          btnPadding={SMALL_SCREEN ? '10px 12px' : undefined}
        >
          제품이미지3
        </InputBoxFile>

        <InputBoxFile
          accept=".pdf"
          placeHolderText={
            SMALL_SCREEN ? 'PDF 파일 (최대 5MB)' : 'PDF 파일 (최대 5MB)만 업로드 가능합니다.'
          }
          placeHolderFontSize={EXTRA_SMALL_SCREEN ? '13px' : undefined}
          fileName={uploadFileName}
          inputRef={uploadFileInputRef}
          onChangeEvent={(e) => onFileChange(e, 4)}
          closeOnClickEvent={() => deleteFile(4)}
          addFileOnClickEvent={openUploadFilePicker}
          btnTitle="첨부파일 등록"
          btnFontSize={SMALL_SCREEN ? '12px' : undefined}
          btnPadding={SMALL_SCREEN ? '10px 12px' : undefined}
        >
          첨부파일 <span style={{ fontSize: '12px' }}>*PDF만 가능</span>
        </InputBoxFile>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            gap: '10px',
            margin: '200px 0 100px 0',
          }}
        >
          <BtnNormal
            flex="1"
            fontSize="16px"
            fontWeight="400"
            color={colorList.gray}
            bgColor={colorList.lightGray}
            padding="15px 20px"
            onClickEvent={() => navigate(-1)}
          >
            취소
          </BtnNormal>
          <BtnNormal
            flex="1"
            fontSize="16px"
            fontWeight="400"
            bgColor={colorList.main}
            padding="15px 20px"
            onClickEvent={onSendProduct}
          >
            등록
          </BtnNormal>
        </div>
      </SectionLayout>
    </>
  );
}

export default ProductWrite;
