import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import icon_first from '../assets/img/icon/icon-first.svg';
import icon_last from '../assets/img/icon/icon-last.svg';
import icon_next from '../assets/img/icon/icon-next_page.svg';
import icon_prev from '../assets/img/icon/icon-prev.svg';

const Pagination = ({ naviPath, totalCount = 40, count = '20', page = '1', conMargin }) => {
  const navigate = useNavigate();

  const totalPages = Math.ceil(totalCount / Number(count));

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    window.scrollTo(0, 0);
    navigate(naviPath + `page=${String(newPage)}&count=${count}`);
  };

  return (
    <Container conMargin={conMargin}>
      <PageBox>
        <PageBtn imgUrl={icon_first} onClick={() => handlePageChange(1)} />
        <PageBtn imgUrl={icon_prev} onClick={() => handlePageChange(Number(page) - 1)} />
      </PageBox>
      <PageBox>
        {Array.from({ length: totalPages }).map((_, index) => (
          <PageNum
            key={index}
            current={Number(page) === index + 1}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </PageNum>
        ))}
      </PageBox>
      <PageBox>
        <PageBtn imgUrl={icon_next} onClick={() => handlePageChange(Number(page) + 1)} />
        <PageBtn imgUrl={icon_last} onClick={() => handlePageChange(totalPages)} />
      </PageBox>
    </Container>
  );
};

export default React.memo(Pagination);

// style
const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  margin: ${({ conMargin }) => conMargin};
`;

const PageBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PageBtn = styled.span`
  width: 32px;
  height: 32px;
  background-size: 28px;
  background-position: center center;
  margin: 0;
  background-image: url(${({ imgUrl }) => imgUrl});
  cursor: pointer;
`;

const PageNum = styled.span`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  color: ${({ current }) => (!current ? '#666666' : '#2e4995')};
  &::before {
    content: '';
    position: absolute;
    display: ${({ current }) => (!current ? 'none' : 'block')};
    width: 100%;
    height: 2px;
    background-color: #2e4995;
    left: 0;
    bottom: 0;
  }
`;
