import React from 'react';
import styled from 'styled-components';

import { colorList } from '../assets/style/theme';

const InputBox = ({
  isRequiredMark = false,
  inputPadding = '10px',
  inputFontSize = '18px',
  inputType = 'text',
  inputValue,
  inputDisabled = false,
  children,
  titleFontSize = '14px',
  isInput = true,
  titlePadding = '10px 0px',
  onChangeEvent,
  onClickEvent,
  containerWidth = '100%',
  inputWidth = '100%',
  inputHeight = 'fit-content',
  htmlFor = '',
  containerMargin = '20px auto',
  containerHeight = 'fit-content',
  justifyContent = 'noone',
  containerPadding = '0 0',
  textAlign = 'left',
  letterSpacing = 'nomal',
  inputDefaultValue,
  inputRef,
  styleProp,
  inputChecked,
  inputName,
  placeholder,
  placeholderFontSize = '15px',
  placeholderColor = colorList.darkGray,
  inputPattern,
  checkedPosition = 'left',
  titleColor = 'black',
  conFlex,
  borderRadius = '10px',
  border = '1px solid rgb(224, 224, 224)',
  conClassName,
  radioCheckGap = '10px',
}) => {
  return (
    <InputContainer
      radioCheckGap={radioCheckGap}
      className={conClassName}
      containerPadding={containerPadding}
      containerWidth={containerWidth}
      inputType={inputType}
      containerMargin={containerMargin}
      containerHeight={containerHeight}
      justifyContent={justifyContent}
      conFlex={conFlex}
    >
      {inputType !== 'checkbox' && inputType !== 'radio' && children && (
        <Title titleFontSize={titleFontSize} titlePadding={titlePadding} titleColor={titleColor}>
          {children}{' '}
          {isRequiredMark ? <span style={{ color: 'rgb(238, 106, 123)' }}>*</span> : null}
        </Title>
      )}
      {(inputType === 'checkbox' && checkedPosition === 'right' && children) ||
      (inputType === 'radio' && checkedPosition === 'right' && children) ? (
        <Label titleFontSize={titleFontSize} htmlFor={htmlFor} titleColor={titleColor}>
          {children}
        </Label>
      ) : null}
      {isInput && (
        <Input
          style={styleProp}
          ref={inputRef}
          defaultValue={inputDefaultValue}
          letterSpacing={letterSpacing}
          textAlign={textAlign}
          id={htmlFor}
          type={inputType}
          value={inputValue}
          onChange={onChangeEvent}
          onClick={onClickEvent}
          disabled={inputDisabled}
          inputPadding={inputPadding}
          inputFontSize={inputFontSize}
          inputWidth={inputWidth}
          inputHeight={inputHeight}
          containerMargin={containerMargin}
          inputType={inputType}
          checked={inputChecked}
          name={inputName}
          placeholder={placeholder}
          placeholderFontSize={placeholderFontSize}
          placeholderColor={placeholderColor}
          onWheel={(e) => e.target.blur()}
          pattern={inputPattern}
          borderRadius={borderRadius}
          border={border}
        />
      )}
      {(inputType === 'checkbox' && checkedPosition === 'left' && children) ||
      (inputType === 'radio' && checkedPosition === 'left' && children) ? (
        <Label titleFontSize={titleFontSize} htmlFor={htmlFor} titleColor={titleColor}>
          {children}
        </Label>
      ) : null}
    </InputContainer>
  );
};

export default React.memo(InputBox);

const InputContainer = styled.div`
  flex: ${({ conFlex }) => conFlex};
  position: relative;
  box-sizing: border-box;
  display: ${({ inputType }) =>
    inputType === 'checkbox' || inputType === 'radio' ? 'flex' : 'block'};
  align-items: ${({ inputType }) =>
    inputType === 'checkbox' || inputType === 'radio' ? 'center' : 'none'};
  justify-content: ${({ justifyContent, inputType }) =>
    (inputType === 'checkbox' || inputType === 'radio') && justifyContent
      ? justifyContent
      : 'none'};
  gap: ${({ inputType, radioCheckGap }) =>
    inputType === 'checkbox' || inputType === 'radio' ? radioCheckGap : 'none'};
  width: ${({ containerWidth }) => containerWidth};
  height: ${({ containerHeight }) => containerHeight};
  margin: ${({ containerMargin }) => containerMargin};
  padding: ${({ containerPadding }) => containerPadding};
`;

const Title = styled.p`
  font-size: ${({ titleFontSize }) => titleFontSize};
  padding: ${({ titlePadding }) => titlePadding};
  color: ${({ titleColor }) => titleColor};
`;

const Input = styled.input`
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  text-align: ${({ textAlign }) => textAlign};
  box-sizing: border-box;
  width: ${({ inputWidth }) => inputWidth};
  height: ${({ inputHeight }) => inputHeight};
  padding: ${({ inputPadding }) => inputPadding};
  font-size: ${({ inputFontSize }) => inputFontSize};
  border: 1px solid #e0e0e0;
  cursor: ${({ inputType }) =>
    inputType === 'checkbox' || inputType === 'radio' ? 'pointer' : 'auto'};
  &:disabled {
    border: 1px solid #dbdbdb;
  }
  &::placeholder {
    font-size: ${({ placeholderFontSize }) => placeholderFontSize};
    color: ${({ placeholderColor }) => placeholderColor};
  }
  border-radius: ${({ borderRadius }) => borderRadius};
  border: ${({ border }) => border};
`;

const Label = styled.label`
  font-size: ${({ titleFontSize }) => titleFontSize};
  padding: ${({ titlePadding }) => titlePadding};
  color: ${({ titleColor }) => titleColor};
`;
